// btn
@use "variables" as *;

@mixin btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.05em;
  transition: all .3s;
  &.middle {
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    font-size: 12px;
  }
  &.small {
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    font-size: 10px;
  }
  &:active {
    filter: brightness(85%);
  }
  &.disabled {
    background-color: $color-gray3;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
    &.error {
      background-color: $color-danger;
    }
  }
}
.btn-main {
  @include btn;
  background-color: $color-accent;
  &:hover, &:focus-visible {
    box-shadow: 
      0px 0px 0 1px #fff,
      0px 0px 0 3px #60d8d8;
  }
  &.small {
    &.disabled:hover {
      box-shadow: none;
    }
  }
  + .btn-main {
    margin-left: 10px;
  }
}

.btn-bdr {
  @include btn;
  line-height: 38px;
  color: $color-gray2;
  border: 1px solid;
  &.middle {
    line-height: 28px;
  }
  &.small {
    line-height: 22px;
  }
  &:hover, &.selected {
    background-color: $color-accent;
    color: #fff;
    border-color: $color-accent;
  }
  &.selected {
    .rect {
      box-shadow: 
      0px 0px 0 1px $color-accent,
      0px 0px 0 2px #fff;
    }
  }
}

.btn-dashed {
  @include btn;
  line-height: 38px;
  color: $color-gray1;
  border: 1px dashed;
  border-radius: 8px;
  &.middle {
    line-height: 28px;
  }
  &.small {
    line-height: 22px;
  }
}

.btn-g {
  @include btn;
  height: 44px;
  line-height: 44px;
  color: $color-gray2;
  border: 2px solid #4285F4;
  &:hover, &:focus-visible {
    box-shadow: 
      0px 0px 0 1px #4285F4,
      0px 0px 0 2px #4285F4;
  }
}

@mixin btn-icon-style {
  flex-shrink: 0;
  width: $md;
  height: $md;
  color: var(--content-text-color-light);
  font-size: $icon-size-p2;
  border-radius: 4px;
  $element: #{&};
  &__xs {
    @extend #{$element};
    width: $xs;
    height: $xs;
    font-size: $icon-size-default;
  }
  &__sm {
    @extend #{$element};
    width: $sm;
    height: $sm;
    font-size: $icon-size-p1;
  }
  &__la {
    @extend #{$element};
    width: $la;
    height: $la;
    font-size: $icon-size-p3;
  }
  &__xl {
    @extend #{$element};
    width: $xl;
    height: $xl;
    font-size: $icon-size-p4;
  }
  &:hover, &:focus-visible {
    background-color: var(--content-hover-bg-color);
    transition: .1s;
    i {
      color: var(--content-text-color);
    }
  }
  &:active, &.active {
    background-color: var(--content-active-bg-color);
  }
}

.dn-btn-icon {
  @include btn-icon-style;
  &__border {
    @include btn-icon-style;
    i {
      border: 1px solid var(--content-text-color-light);
    }
    &:hover, &:focus-visible {
      i {
        color: var(--content-text-color-light);
      }
    }
  }
}

@mixin btn-text-style {
  flex-shrink: 0;
  height: $md;
  padding: 0 8px;
  font-size: $ft-size-default;
  border-radius: 4px;
  $element: #{&};
  &__xs {
    @extend #{$element};
    height: $xs;
    font-size: $ft-size-m2;
  }
  &__sm {
    @extend #{$element};
    height: $sm;
    font-size: $ft-size-m1;
  }
  &__la {
    @extend #{$element};
    height: $la;
    font-size: $ft-size-p1;
  }
  &__xl {
    @extend #{$element};
    height: $xl;
    font-size: $ft-size-p2;
  }
  &:hover, &:focus-visible {
    background-color: var(--btn-bg-color);
    color: #fff;
    transition: .1s;
  }
  &:active {
    background-color: var(--btn-active-bg-color);
    color: #fff;
  }
}

.dn-btn-text {
  @include btn-text-style;
  &__border {
    @include btn-text-style;
    color: var(--content-text-color-light);
    border: 1px solid var(--content-bdr-color);
    &:hover, &:focus-visible {
      border: 1px solid var(--btn-bg-color);
    }
  }
}
