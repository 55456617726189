@charset "utf-8";
@use "./modules/variables" as *;

.search-box {
  padding: 0 14px 0 32px;
  border-radius: 40px;
  + .search-icon {
    position: absolute;
    left: 12px;
    transition: all .3s;
  }
  &.small {
    height: 25px;
    line-height: 25px;
    padding: 0 14px 0 20px;
    + .search-icon {
      left: 6px;
      right: unset;
    }
  }
}

/* content */
// image box
$size-min: 0;
$size-max: 50;
@for $i from $size-min through $size-max {
  .img-box-#{$i * 2} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: content-box;
    width: #{$i * 2}px;
    height: #{$i * 2}px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    &.rect {
      border-radius: 2px;
    }
    &.circle {
      border-radius: 50%;
    }
    &.change {
      transition: all .3s;
      &:hover {
        box-shadow: 
          0px 0px 0 1px #fff,
          0px 0px 0 3px #60d8d8;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.contain {
      background-color: $color-gray3;
      img {
        object-fit: contain;
      }
    }
  }
}

.img-thumb {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: content-box;
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.img-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  color: #fff;
}

.list-box {
  .list-row {
    &:last-of-type {
      border: none;
    }
  }
}

.list-row {
  border-bottom: 1px solid $color-gray4;
  &:hover {
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(1, 0, 35, 0.1);
    z-index: 20;
    transition: .3s;
  }
}
.list-select {
  &:hover {
    background-color: $color-accent;
    color: #fff;
    box-shadow: 0 2px 6px 0 rgba(1, 0, 35, 0.1);
    z-index: 20;
    transition: .3s;
  }
  &.selected {
    background-color: $color-accent;
    color: #fff;
  }
}

.close-icon {
  position: absolute;
  right: 6px;
  top: 6px;
  padding: 4px;
  z-index: 100;
  &:hover {
    background-color: $color-gray4;
    border-radius: 4px;
    transition: .3s;
  }
}
/* content */

// text-editor用　西田暫定対応
ol{
  counter-reset: item0;
  list-style-type: none;
  padding-left: 0;
}
ol li.indent-0 {
  counter-reset: item1;
}

ol li.indent-1 {
  margin-left: 20px !important;
  // list-style: circle !important;
}
ol li.indent-2 {
  margin-left: 40px !important;
  list-style: circle !important;
}
ol li.indent-3 {
  margin-left: 60px !important;
  list-style: circle !important;
}

ol li:before {
  padding-right: .5em;
  font-weight: bold;
  color: #b40000;
}

ol li.indent-0:before {
  counter-increment: item0;
  content: counter(item0)'.';
}

ol li.indent-1:before {
  counter-increment: item1;
  content: counter(item1, lower-roman)'.';
}

ul.editor-ul li {
  margin-left: 10px;
  list-style: disc;
}
ul.editor-ul li.indent-0 {
  margin-left: 10px;
  list-style: disc;
}
ul.editor-ul li.indent-1 {
  margin-left: 20px;
  list-style: circle;
}
ul.editor-ul li.indent-2 {
  margin-left: 30px;
  list-style: square;
}
ul.editor-ul li.indent-3 {
  margin-left: 40px;
  list-style: square;
}
ul.editor-ul li.indent-4 {
  margin-left: 50px;
  list-style: square;
}
ul.editor-ul li.indent-5 {
  margin-left: 60px;
  list-style: square;
}

// blockquote:before {
//     background: rgba(var(--sk_foreground_low_solid,221,221,221),1);
//     border-radius: 8px;
//     bottom: 0;
//     content: "";
//     display: block;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 4px;
// }

code {
  background-color: rgba(var(--sk_foreground_min,29,28,29),.04);
  border: 1px solid var(--saf-0);
  border-radius: 3px;
  color: rgba(var(--sk_raspberry_red,224,30,90),1);
  padding: 2px 3px 1px;
}

blockquote {
  position: relative;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  font-style: italic;
  background: #f5f5f5;
  color: #777777;
  border-left: 4px solid #9dd4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
}

blockquote:before{
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  // content: "\f10d";
  // font-family: FontAwesome;
  color: #9dd4ff;
  font-size: 30px;
  line-height: 1;
  font-weight: 900;
}

blockquote p {
  padding: 0;
  margin: 7px 0;
  line-height: 1.7;
}

blockquote cite {
  display: block;
  text-align: right;
  color: #888888;
  font-size: 0.9em;
}

// コードブロック(投稿用)
.ql-code-block:first-child {
  border-top: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: 4px;
  padding-top: 8px;

}
.ql-code-block {
  word-wrap: break-word;
  background: rgba(var(--sk_foreground_min_solid,248,248,248),1);
  border-left: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  border-right: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  font-family: Monaco,Menlo,Consolas,Courier New,monospace!important;
  font-size: 12px;
  font-variant-ligatures: none;
  line-height: 1.50001;
  // margin-bottom: 14px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: normal;
}
.ql-code-block:after {
  background: rgba(var(--sk_foreground_min_solid,248,248,248),1);
  border-bottom: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  border-right: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
  bottom: -18px;
  content: "";
  display: block;
  height: 8px;
  left: -1px;
  margin-bottom: 10px;
  position: absolute;
  width: 100%;
  width: calc(100% + 2px);
}

// コードブロック(メッセージ表示用)
pre {
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: monospace;
  white-space: pre-wrap; /* テキストの折り返しを有効にする */
}