// sidebar
@use "../modules/variables" as *;

#dn-sidebar-container {
  grid-area: dn-sidebar-container;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 264px;
  height: 100%;
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-text-color);
  z-index: 70;
  transition: .3s ease-out;
  &.is-collapsed__sidebar {
    width: 50px;
    .dn-sidebar-header {
      justify-content: center;
      &__item {
        display: none;
        &_right {
          padding-right: 0;
          margin-left: 0;
          .dn-btn-icon {
            margin-left: 0;
          }
        }
      }
    }
    .dn-sidebar-list {
      &__item {
        &_right {
          display: none;
        }
      }
      .dn-sidebar-project__title {
        display: none;
      }
      .dn-sidebar-project {
        justify-content: flex-end;
        padding: 0 13px;
        .dn-sidebar-toggle-icon {
          display: none;
        }
        &__member {
          display: none;
        }
        &__name {
          display: none;
        }
      }
      .dn-sidebar-case {
        justify-content: flex-end;
        padding: 0 13px;
      }
    }
  }
  .dn-btn-icon {
    &:hover {
      i {
        background-color: #fff;
      }
    }
  }
}

.dn-sidebar {
  &-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid var(--sidebar-bdr-color);
    img {
      height: 60%;
    }
    &__item {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      padding-left: 16px;
      cursor: pointer;
      &_right {
        display: flex;
        flex-shrink: 0;
        padding-right: 16px;
        margin-left: auto;
        .dn-btn-icon {
          margin-left: 4px;
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__item {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 16px;
      &_right {
        display: flex;
        margin-left: auto;
        .dn-btn-icon {
          margin-left: 4px;
          &:last-of-type {
            margin-left: 0;
          }
        }
      }
      &_menu {
        opacity: 0;
        transition: .1s;
      }
    }
    i.dn-sidebar-toggle-icon {
      display: flex;
      flex-shrink: 0;
      margin: 0 4px 0 -6px;
      transition: .1s;
      &.is-collapsed__projects, &.is-collapsed__cases {
        transform: rotate(-90deg);
        transition: .1s;
      }
    }
  }

  &-project {
    display: flex;
    align-items: center;
    height: 32px;
    margin-top: 8px;
    padding: 0 16px 0 24px;
    &:hover {
      .dn-sidebar-list__item_menu {
        opacity: 1.0;
        transition: .1s;
      }
    }
    &__member {
      display: flex;
      align-items: center;
      padding: 0 16px 8px 72px;
    }
    &__name {
      margin-left: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-case {
    display: flex;
    align-items: center;
    height: 28px;
    margin: 0 8px 0 44px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: var(--sidebar-hover-bg-color);
      transition: .1s;
      .dn-sidebar-list__item_menu {
        opacity: 1.0;
        transition: .1s;
      }
    }
    &.active {
      background-color: var(--sidebar-active-bg-color);
      color: #fff;
    }
  }
}