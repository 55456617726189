// popover
@use "../modules/variables" as *;

$tooltip-opacity: 1.0;
$tooltip-max-width: 60vw ;
$tooltip-padding-x: 12px;
$tooltip-padding-y: 8px;
$tooltip-bg-dark : $color-theme1;
$tooltip-color-dark: #fff;
$tooltip-border-radius: 4px;

$tooltip-arrow-width: 12px;
$tooltip-arrow-height: 6px;
$tooltip-arrow-color-dark: $color-theme1;

$gap-value: 2px;
$shadow-default: 0 0 0 1px rgba($color-gray4, 0.5), 0 2px 4px $color-gray4;
$shadow-black: 0 0 1px 1px $color-gray8;
$shadow-error: 0 0 0 1px $color-danger;
$shadow-warning: 0 0 0 1px $color-warning;

// arrow
@mixin arrow-sytle {
  position: absolute;
  display: block;
  width: $tooltip-arrow-width;
  height: $tooltip-arrow-height;
  &::before {
    content: "";
    position: absolute;
    border: 4px solid #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    z-index: 10;
  }
  &::after {
    content: "";
    position: absolute;
    border: 4px solid #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    z-index: -10;
    box-shadow: $shadow-default;
  }
}

.rd-popover-top {
  .rd-popover-arrow {
    @include arrow-sytle;
  }
}
.rd-popover-bottom {
  .rd-popover-arrow {
    @include arrow-sytle;
    &::before {
      top: 100%;
    }
    &::after {
      top: 100%;
    }
  }
}
.rd-popover-right {
  .rd-popover-arrow {
    @include arrow-sytle;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    &::before {
      top: 50%;
      left: 3px;
    }
    &::after {
      top: 50%;
      left: 3px;
    }
  }
}
.rd-popover-left {
  .rd-popover-arrow {
    @include arrow-sytle;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    &::before {
      top: 50%;
      left: -100%;
    }
    &::after {
      top: 50%;
      left: -100%;
    }
  }
}

.rd-popover-body {
  display: flex;
  flex-direction: column;
  max-width: $tooltip-max-width;
  line-height: 1.2;
  background-color: #fff;
  color: $color-ini;
  border-radius: $tooltip-border-radius;
  box-shadow: $shadow-default;
  overflow: hidden;
}

.is-tooltip {
  .rd-popover-body {
    position: relative;
    padding: $tooltip-padding-y $tooltip-padding-x;
  }
  &.dark {
    .rd-popover-arrow {
      &::before {
        border: 4px solid $tooltip-bg-dark;
      }
      &::after {
        border: 4px solid $tooltip-bg-dark;
        box-shadow: $shadow-black;
      }
    }
    .rd-popover-body {
      background-color: $tooltip-bg-dark;
      color: $tooltip-color-dark;
      box-shadow: $shadow-black;
    }
  }
  &.error {
    .rd-popover-arrow {
      &::before {
        border: 4px solid lighten($color-danger, 48%);
      }
      &::after {
        border: 4px solid lighten($color-danger, 48%);
        box-shadow: $shadow-error;
      }
    }
    .rd-popover-body {
      background-color: lighten($color-danger, 48%);
      color: $color-danger;
      box-shadow: $shadow-error;
    }
  }
  &.warning {
    .rd-popover-arrow {
      &::before {
        border: 4px solid lighten($color-warning, 48%);
      }
      &::after {
        border: 4px solid lighten($color-warning, 48%);
        box-shadow: $shadow-warning;
      }
    }
    .rd-popover-body {
      background-color: lighten($color-warning, 48%);
      color: $color-warning;
      box-shadow: $shadow-warning;
    }
  }
  &.no-space {
    .rd-popover-body {
      padding: 0;
    }
  }
}