// popovers
@use "../modules/variables" as *;

.dn-pop-menu {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  padding: 4px 0;
  &__item {
    display: flex;
    align-items: center;
    padding: 0 4px;
    i {
      font-size: $icon-size-p2;
    }
    button {
      width: 100%;
      text-align: left;
    }
  }
}

// pipeline files
.dn-pipeline-files {
  &__item {
    padding: 4px 12px;
    &_latest {
      display: flex;
      align-items: center;
    }
  }
  &__tag {
    margin-left: 8px;
  }
  &__title {
    margin-left: 8px;
    font-size: $ft-size-m1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__tags {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 4px;
    margin-left: 32px;
    border-radius: 4px;
  }
}

// pipeline message menu
.dn-pipeline-item {
  &__menu {

  }
}