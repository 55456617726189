// content
@use "../modules/variables" as *;

.dn-workspace-container {
  display: flex;
  height: 100%;
}

.dn-case-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

// case
.dn-case {
  &-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 55px; // fix shadow height
    padding: 0 16px;
    background-color: var(--content-bg-color);
    box-shadow: 0 1px 0 0 var(--content-shadow-color);
    z-index: 200;
    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    &__info {
      display: flex;
      align-items: center;
      &__text {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
      }
      &__title {
        font-size: $ft-size-p4;
        font-weight: 700;
      }
      &__desc {
        color: var(--content-text-color-light);
        font-size: $ft-size-m2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &__btn-detail {
        margin-left: 8px;
      }
    }
    button {
      margin-left: 12px;
    }
  }
  &-body {
    display: flex;
    height: 100%;
    padding: 8px;
  }
}

// pipeline
.dn-pipeline {
  &-container {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    width: 100%;
    padding: 8px;
  }
  &-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    padding-right: 16px;
    padding-left: 16px;
    background-color: var(--content-bg-color);
    border: 1px solid var(--content-bdr-color);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
      button {
        margin-left: 8px;
      }
    }
    &__title {
      font-weight: bold;
    }
  }
  &-body {
    background-color: var(--content-bg-color);
    border-right: 1px solid var(--content-bdr-color);
    border-left: 1px solid var(--content-bdr-color);
  }
  &-footer {
    padding: 16px;
    background-color: var(--content-bg-color);
    border: 1px solid var(--content-bdr-color);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &-item {
    display: flex;
    padding: 8px 16px;
    &:first-of-type {
      margin-top: 4px;
    }
    &:last-of-type {
      margin-bottom: 4px;
    }
    &__left {
      flex-shrink: 0;
    }
    &__right {
      margin-left: 8px;
    }
    &__sent {
      &__info {
        display: flex;
        align-items: flex-end;
      }
      &__text {
        margin-top: 4px;
        white-space: pre-wrap;
        word-wrap: break-word;
        line-height: 1.52;
      }
    }
    &:hover {
      background-color: var(--content-hover-bg-color);
    }
  }
}

.dn-sender-name {
  font-weight: bold;
}
.dn-sent-time {
  margin-left: 8px;
  color: var(--content-text-color-light);
  font-size: $ft-size-m2;
}