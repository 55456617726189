// scroll
@use "variables" as *;

::-webkit-scrollbar {
  display: none;
}

.hidden-box {
  overflow: hidden;
}

.scroll-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .scrollbar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 8px;
    visibility: hidden;
    z-index: 1000;
    .scrollbar-thumb {
      position: relative;
      top: 3px;
      right: 4px;
      min-height: 50px;
      background-color: $color-gray1;
      border-radius: 20px;
      opacity: 0.6;
    }
  }
}

.scroll-y {
  margin-right: -20px;
  padding-right: 20px;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &.absolute {
    position: absolute;
  }
}

.scroll-box-x {
  overflow: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}