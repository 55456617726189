@font-face {
  font-family: 'rdicons';
  src:  url(./../../fonts/rdicons.eot?4w81hj);
  // src:  url(@/assets/fonts/rdicons.eot?4w81hj);
  src:  url(./../../fonts/rdicons.eot?4w81hj#iefix) format('embedded-opentype'),
    url(./../../fonts/rdicons.ttf?4w81hj) format('truetype'),
    url(./../../fonts/rdicons.woff?4w81hj) format('woff'),
    url(./../../fonts/rdicons.svg?4w81hj#rdicons) format('svg');
  font-display: swap;
}

[class^="rdi-"], [class*="rdi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'rdicons' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // fix vertical
  vertical-align: -2px;
}

.rdi-modal-line2:before {
  content: "\e901";
}
.rdi-modal-line:before {
  content: "\e900";
}
