// post area
@use "../modules/variables" as *;

.dn-postarea {
  &-container {
    border: 1px solid var(--content-bdr-color);
    border-radius: 6px;
    transition: .1s;
    &:focus-within {
      border-color: var(--focus-color);
      box-shadow: 0 0px 1px 0px var(--focus-color);
      transition: .1s;
      .dn-postarea-toolbar, .dn-postarea-footer {
        border-color: var(--focus-color);
      }
    }
  }
  &-toolbar {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 4px;
    border-bottom: 1px solid var(--content-bdr-color);
    button {
      margin: 0 2px;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    padding: 4px;
    border-top: 1px solid var(--content-bdr-color);
    &__divider {
      width: 1px;
      height: 72%;
      margin: 0 12px;
      background-color: var(--content-bdr-color);
    }
    button {
      margin: 0 2px;
    }
  }
}

.dn-message-input {
  display: flex;
  flex-direction: column;
  max-height: 320px;
  padding: 10px;
  overflow: hidden;
  &:focus-visible {
    box-shadow: none;
    border-radius: 0;
  }
  &::before {
    content: attr(data-placeholder);
    position: absolute;
    color: var(--content-text-color-light);
    opacity: 0.8;
  }
}