// font
$base-font: 
  'Lato', 
  'Noto Sans JP', 
  'Hiragino Kaku Gothic ProN', 
  'Hiragino Sans', 
  sans-serif
;

// colors
$color-ini: #484848;
$color-bg: #f2f6f9;
$color-theme: #09313F;
$color-accent: #37b9b9;
$color-accent-dark: #289696;
$color-focus: #2f5e74;
$color-info: #3783b9;
$color-success: #36b575;
$color-danger: #d65050;
$color-warning: #f3b935;

$color-bg-hover: rgba(24, 24, 24, 0.08);
$color-shadow:rgba(1, 0, 35, 0.2);
$color-shadow-light:rgba(211, 247, 235, 0.15);

$color-grays: (
  1: #3d3d3e,
  2: #626264,
  3: #8799a1,
  4: #b0bec5,
  5: #ebf4fb,
  6: #f2f7fb,
);
$color-gray1: #3d3d3e;
$color-gray2: #626264;
$color-gray3: #8799a1;
$color-gray4: #b0bec5;
$color-gray5: #d4dde3;
$color-gray6: #f2f7fb;
// add by nishida
$color-gray8: #dbe2e3;

$color-theme1: #09313F;
$color-theme2: #0E434E;
$color-theme3: #15575D;
$color-theme4: #1D6D6D;
$color-theme5: #4BA79F;
$color-theme6: #76D3C3;
$color-theme7: #A9F0DE;
$color-theme8: #D3F7EB;

// size
$xxs: 20px;
$xs: 24px;
$sm: 28px;
$md: 32px;
$la: 36px;
$xl: 40px;
$xxl: 48px;


// font size
$ft-size-default : 0.875rem;
$ft-size-m1: 0.8125rem;
$ft-size-m2: 0.75rem;
$ft-size-m3: 0.6875rem;
$ft-size-m4: 0.625rem;
$ft-size-p1: 0.9375rem;
$ft-size-p2: 1.0rem;
$ft-size-p3: 1.0625rem;
$ft-size-p4: 1.125rem;

// icon size
$icon-size-default : 0.875rem;
$icon-size-p1: 1.125rem;
$icon-size-p2: 1.375rem;
$icon-size-p3: 1.625rem;
$icon-size-p4: 1.875rem;
$icon-size-p5: 2.125rem;

// theme
:root {
  --bg-color: #{$color-bg};
  --focus-color: #{$color-focus};

  --header-bg-color: #{$color-theme};
  --header-shadow-color: #{$color-shadow-light};

  --sidebar-bg-color: #{$color-theme};
  --sidebar-text-color: #{$color-gray6};
  --sidebar-bdr-color: #{$color-theme3};
  --sidebar-hover-bg-color: #{$color-theme2};
  --sidebar-active-bg-color: #{$color-theme3};

  --content-bg-color: #fff;
  --content-text-color: #{$color-ini};
  --content-text-color-light: #{$color-gray3};
  --content-bdr-color: #{$color-gray4};
  --content-shadow-color: #{$color-shadow};
  --content-hover-bg-color: #{$color-gray6};
  --content-active-bg-color: #{$color-gray5};

  --btn-bg-color: #{$color-accent};
  --btn-active-bg-color: #{darken($color-accent, 10%)};
}