// base
@charset "utf-8";
@use "../modules/variables" as *;

html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: $color-ini;
  font-size: 14px;
  font-family: $base-font;
  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  cursor: auto;
  // font-feature-settings: "palt";
  // letter-spacing: 0.05rem;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a.disabled {
  pointer-events: none;
}

*.active,
*.soon {
  cursor: default;
  outline: none;
}

*:focus {
  outline: none;
}
*:focus-visible {
  box-shadow: 0 0px 1px 1px var(--focus-color);
  border-radius: 4px;
}

.text-link {
  // color: $color-link;
  // border-bottom: 1px dotted $color-link;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #009bf5;
    border-bottom: 1px dotted #009bf5;
    text-decoration: none;
  }
}

div,
p,
li,
a,
span {
  max-height: 999999px;
}

#donatello-ui {
  display: grid;
  grid-template-rows: 40px auto;
  grid-template-columns: 264px 1fr;
  grid-template-areas: 
    'dn-common-nav dn-common-nav'
    'dn-sidebar-container dn-main-container';
  height: 100%;
  background-color: var(--bg-color);
  &.is-collapsed__sidebar {
    grid-template-columns: 50px 1fr;
  }
}

/* main-container */
#dn-main-container {
  grid-area: dn-main-container;
  position: relative;
  width: 100%;
  font-size: 0.875rem;
  overflow-y: auto;
}

.dn-common-nav {
  grid-area: dn-common-nav;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: var(--header-bg-color);
  box-shadow: 0 1px 0 0 var(--header-shadow-color);
  z-index: 999;
  &__center {
    flex: 1;
  }
  &__right {
    justify-content: flex-end;
  }
}
/* main-container */