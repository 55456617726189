// icon image
@use "../modules/variables" as *;

@mixin icon-style {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: content-box;
  width: $md;
  height: $md;
  overflow: hidden;
  $element: #{&};
  &__xxs {
    @extend #{$element};
    width: $xxs;
    height: $xxs;
  }
  &__xs {
    @extend #{$element};
    width: $xs;
    height: $xs;
  }
  &__sm {
    @extend #{$element};
    width: $sm;
    height: $sm;
  }
  &__la {
    @extend #{$element};
    width: $la;
    height: $la;
  }
  &__xl {
    @extend #{$element};
    width: $xl;
    height: $xl;
  }
  &__xxl {
    @extend #{$element};
    width: $xxl;
    height: $xxl;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.dn-icon-project, .dn-icon-case, .dn-file-thumb {
  @include icon-style;
  border-radius: 4px;
}
.dn-icon-user {
  @include icon-style;
  border-radius: 8px;
}

$size-min: 0;
$size-max: 50;
@for $i from $size-min through $size-max {
  .img-box-#{$i * 2} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: content-box;
    width: #{$i * 2}px;
    height: #{$i * 2}px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    &.rect {
      border-radius: 2px;
    }
    &.circle {
      border-radius: 50%;
    }
    &.change {
      transition: all .3s;
      &:hover {
        box-shadow: 
          0px 0px 0 1px #fff,
          0px 0px 0 3px #60d8d8;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.contain {
      background-color: $color-gray3;
      img {
        object-fit: contain;
      }
    }
  }
}