// common
@use "../modules/variables" as *;

@mixin btn-details-style {
  display: flex;
  align-items: center;
  height: $md;
  background-color: var(--content-bg-color);
  color: var(--content-text-color-light);
  border: 1px solid var(--content-bdr-color);
  border-radius: 8px;
  $element: #{&};
  &__xs {
    @extend #{$element};
    height: $xs;
    font-size: 0.75rem;
  }
  &__sm {
    @extend #{$element};
    height: $sm;
    font-size: 0.75rem;
  }
  &__la {
    @extend #{$element};
    height: $la;
  }
  &:hover, &:focus-visible {
    background-color: var(--content-hover-bg-color);
    color: var(--content-text-color);
    border: 1px solid var(--content-bdr-color);
  }
  &:active {
    background-color: var(--content-active-bg-color);
  }
}
.dn-btn-details {
  @include btn-details-style;
  &__divider {
    width: 1px;
    height: 72%;
    background-color: var(--content-bdr-color);
  }
  &__summary_num {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  &__case {
    &_member {
      display: flex;
      align-items: center;
      padding: 0 4px;
      .dn-icon-user__xs + .dn-icon-user__xs {
        margin-left: 2px;
      }
    }
  }
  &__pipeline {
    &_running, &_done {
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 1.0;
      padding: 0 2px;
      font-size: 0.75rem;
    }
    &_running {
      margin-left: 4px;
      i {
        margin-right: 2px;
        color: $color-info;
        font-size: $icon-size-default;
      }
    }
    &_done {
      margin-right: 4px;
      i {
        margin-right: 2px;
        color: $color-success;
        font-size: $icon-size-default;
      }
    }
  }
  &__text {
    padding: 0 8px;
  }
}

.dn-tag {
  line-height: 1.0;
  padding: 4px 6px;
  font-size: $ft-size-m1;
  border-radius: 4px;
  $element: #{&};
  &__sm {
    font-size: $ft-size-m2;
  }
  &__large {
    font-size: $ft-size-default;
  }
  &__v {
    @extend #{$element};
    background-color: lighten($color-gray4, 20%);
    color: $color-gray3;
    &:hover {
      background-color: lighten($color-gray4, 16%);
      color: darken($color-gray3, 10%);
    }
    &_latest {
      @extend #{$element};
      background-color: lighten($color-accent-dark, 52%);
      color: $color-accent-dark;
      &:hover {
        background-color: lighten($color-accent-dark, 48%);
        color: darken($color-accent-dark, 10%);
      }
    }
  }
}

.dn-sidepeek-container {
  flex-shrink: 0;
  width: 440px;
  background-color: var(--content-bg-color);
  box-shadow: -1px 0 6px 1px var(--content-shadow-color);
  z-index: 400;
}