// structure
@use "variables" as *;

[class*="layout-"], [class*="flex"] {
  position: relative;
}

.layout-block {
  display: block;
}
.layout-inline-block {
  display: inline-block;
}
.layout-column {
  display: flex;
  flex-direction: column;
}
.layout-row {
  display: flex;
  flex-direction: row;
}
.c-between {
  justify-content: space-between;
}
.c-around {
  justify-content: space-around;
}
.c-center {
  justify-content: center;
}
.c-end {
  justify-content: flex-end;
}
.i-start {
  align-items: flex-start;
}
.i-end {
  align-items: flex-end;
}
.i-center {
  align-items: center;
}
.i-baseline {
  align-items: baseline;
}
.s-start {
  align-self: flex-start;
}
.s-center {
  align-self: center;
}
.s-end {
  align-self: flex-end;
}
.flex-grow {
  flex-grow: 2;
  -webkit-flex: auto;
  flex: auto;
}
.flex-shrink {
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}

[class*="area-"] {
  position: relative;
}

// width, height, margin, padding
$value-min: 0;
$value-max: 500;
@for $i from $value-min through $value-max {
  // width %
  .area-#{$i} {
    width: #{$i}#{'%'};
  }
  // width px
  .area-#{$i}px {
    width: #{$i}px;
  }
  // min-width px
  .area-min-#{$i}px {
    min-width: #{$i}px;
  }
  // max-width px
  .area-max-#{$i}px {
    max-width: #{$i}px;
  }

  // height %
  .height-#{$i} {
    height: #{$i}#{'%'};
  }
  // height px
  .height-#{$i}px {
    height: #{$i}px;
  }
  // min-height px
  .height-min-#{$i}px {
    min-height: #{$i}px;
  }
  // man-height px
  .height-max-#{$i}px {
    max-height: #{$i}px;
  }

  // margin
  .mt-#{$i} {
      margin-top: #{$i}px;
  }
  .mr-#{$i} {
      margin-right: #{$i}px;
  }
  .mb-#{$i} {
      margin-bottom: #{$i}px;
  }
  .ml-#{$i} {
      margin-left: #{$i}px;
  }

// padding box
  .box-p#{$i} {
    position: relative;
    padding: #{$i}px;
  }
  .box-w-p#{$i} {
    position: relative;
    padding-right: #{$i}px;
    padding-left: #{$i}px;
  }
  .box-h-p#{$i} {
    position: relative;
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}

.height-100vh {
  height: 100vh;
}

// font-size
.ft-size-0 {
  font-size: 0;
}
.ft-size-mm {
  font-size: 0.6rem;
}

$ft-size-min: 1;
$ft-size-max: 15;
@for $i from $ft-size-min through $ft-size-max {
  .ft-size-#{$i} {
    font-size: #{0.625 + 0.0625 * ($i - 1)}rem;
  }
  .icon-size-#{$i} {
    font-size: #{0.625 + 0.125 * ($i - 1)}rem;
  }
}

.ft-weight-normal {
  font-weight: normal;
}
.ft-weight-bold {
  font-weight: bold;
}

// border
.bdr-box {
  border-style: solid;
  border-width: 1px;
}
.bdr-dashed {
  border-style: dashed;
  border-width: 1px;
}

.bdr-top {
  border-top-style: solid;
  border-top-width: 1px;
}
.bdr-right {
  border-right-style: solid;
  border-right-width: 1px;
}
.bdr-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bdr-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.bdr-radius {
  border-radius: 4px;
}
.bdr-top-radius {
  border-radius: 4px 4px 0 0;
}
.bdr-bottom-radius {
  border-radius: 0 0 4px 4px;
}

.round-box {
  border-radius: 50px;
}

// shadow
.shadow-box {
  box-shadow: 0 1px 4px 2px rgba(1, 0, 35, 0.1);
  z-index: 50;
}
.shadow-top {
  box-shadow: 0 4px 4px 4px rgba(1, 0, 35, 0.1);
  z-index: 50;
}
.shadow-bottom {
  box-shadow: 0 4px 4px -3px rgba(1, 0, 35, 0.1);
  z-index: 50;
}
.shadow-right {
  box-shadow: 1px 0 0 rgba(1,0,35,0.1);
  z-index: 50;
}

// text
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-ellipsis2 {
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.2;
  overflow: hidden;
  word-break: break-all;
}
.text-break {
  word-break: break-all;
}
.text-area {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.link-under-inline {
  display: inline-block;
  border-bottom: 1px solid;
  cursor: pointer;
  &:hover {
    color: #000;
  }
}

// input, textarea
input {
  height: 34px;
  line-height: 34px;
  &.big {
    height: 44px;
    line-height: 44px;
  }
}
input, textarea {
  padding: 8px 10px;
	background: #fff;
	color: $color-ini;
	font-family: 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 游ゴシック体, 'Yu Gothic', YuGothic, メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	border: 1px solid $color-gray1;
  border-radius: 4px;
  transition: all .3s;
  .no-border {
    width: inherit;
    height: inherit;
    line-height: inherit;
    padding: 0;
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.line-vertical {
  display: block;
  width: 1px;
  border: none;
}
.line-horizontal {
  display: block;
  height: 1px;
  border: none;
}